import req from './request'

function getConfig(data){
	return req.get('/app/config/get',data)
}

function getEvaluate(data){
	return req.get('/app/config/evaluate',data)
}
function getClick(data){
	return req.get('/app/config/click',data)
}
function getInstall(data){
	return req.get('/app/config/install',data)
}
export default{
	getConfig,
	getEvaluate,
	getClick,
	getInstall
}
