function getUrlLocal() {
  let url=window.location.href;
  console.log(url,'url')
  let params={}
  if(url.indexOf('?')<0){
    return
  }
  let arr=url.split('?')
  if(arr.length>0){
    let str=arr[1]
    console.log(str,'str')
    if(str.indexOf('&')>0){
      let arr=str.split('&')
      arr.forEach(item=>{
        let tmp=item.split('=')
        params[tmp[0]]=tmp[1]
      })
    }else{
      let arr=str.split('=')
      params[arr[0]]=arr[1]
    }
  }
  return params
}

export default {
  getUrlLocal
}
